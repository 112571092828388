import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
//import ReCAPTCHA from "react-google-recaptcha";

export const Nav = styled.div`
  background-color: black;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;
  color: black;
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 1100px;
`;

export const NavLogo = styled(LinkR)`
  color: whitesmoke;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-left: 24px;
  font-weight: bold;
  text-decoration: none;
`;

// export const ReCapcha = styled(ReCAPTCHA)`
//   background: white;
//   border-radius: 4px;
//   border-right: 1px solid #d8d8d8;
//   overflow: hidden;
//   justify-content: center;
//   margin: 0 auto;
// `;
