import React, { useState } from "react";
import PropertyDetail from "./data";
import "./resource.css";
import Arr from "../../images/rig.jpg";
import { Nav, NavLogo, NavbarContainer} from "./navElement";

const Resource = () => {
  const [detail, setDetail] = useState(PropertyDetail);
  return (
    <>
      <div>
        <Nav>
          <NavbarContainer>
            <NavLogo to="/">Tryambh</NavLogo>
          </NavbarContainer>
        </Nav>

        <section className="property">
          <div className="center">
            <h3>Resources</h3>
          </div>
          <div className="row">
            {detail.map((detail) => {
              return (
                <div key={detail.id} className="column">
                  <div className="single-property">
                    <div className="card">
                      <div className="property-thumb">
                        <div className="property-tag" key={detail.id}>
                          {detail.tags ? detail.tags : null}
                        </div>
                        <img className="imgs" src={detail.Image} alt="" />
                      </div>
                      <div className="property-content">
                        <h3 key={detail.id}>{detail.heading}</h3>
                        <div className="mark">
                          <i className="fa-solid fa-location-dot"></i>
                          <span key={detail.id}>{detail.span}</span>
                        </div>
                        <>{detail.download ? 
                        <a className="amount" href={Arr} download="myFile">
                          Download
                        </a>
                        : ""}
                        </>
                        {/* <ReCapcha sitekey="6LeUKyokAAAAAC-2jbwKEm2zL22IK6YEoIPWzPbv" />
                        <a className="amount" href={Arr} download="myFile">
                          Download
                        </a> */}
                      </div>
                      {/* <div className="property-footer">
                      <ul>
                        <li>
                          <span>{detail.size}</span>
                        </li>
                        <li>
                          <img src={detail.bedImage} alt="" />
                        </li>
                        <li>
                          <img src={detail.bed} alt="" />
                        </li>
                      </ul>
                    </div> */}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {/* <div className="more-property">
          <a className="property-btn" href="#">
            More Property
          </a>
        </div> */}
        </section>
      </div>
    </>
  );
};

export default Resource;
