import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages";
import ContactPage from "./pages/contact";
import ResourcesPage from "./pages/resources";
import TimelinePage from "./pages/timeline";


export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element=<Home /> />
        <Route path="/contact" element=<ContactPage /> />
        <Route path="/resources" element=<ResourcesPage /> />
        <Route path="/timeline" element=<TimelinePage /> />
      </Routes>
    </Router>
  );
}
