import Footer from "../components/Footer";
import TimeLine from "../components/TimeLine/TimeLine";

const TimelinePage = () => {
  return (
    <>
      <TimeLine />
      <Footer />
    </>
  );
};

export default TimelinePage;