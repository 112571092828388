import {
  Container,
  Form,
  Formcontent,
  FormH1,
  FormWrap,
  Icon,
  FormLabel,
  FormInput,
  FormButton,
  Text,
  ReCapcha,
} from "./ContactElements";
import React, { useState,useRef,useCallback } from "react";
import {notification } from 'antd';
import {db} from '../firebaseConfig';
import { addDoc,collection } from "firebase/firestore";
import Navbarpages from "../NavBarPages/Navbarpages";
import Footer from "../Footer"


const ContactForm = () => {
  const recaptchaRef = React.createRef();
    const userCollectionRef = collection(db,"contactdata")
    const [inputs, setInputs] = useState({});
    const [disableSubmit,setDisableSubmit] = useState(true);

    const openNotification = (e) => {
      notification.success({
        message: e
      });
    };

    const errorNotification = (e) => {
      notification.error({
        message: e
      });
    };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }

  const handleSubmit = async(event) => {
    event.preventDefault();
    const recaptchaValue = recaptchaRef.current.getValue();
    inputs.newsletter = document.getElementById('checkboxid').checked;
    if (!recaptchaValue) {
      errorNotification("Captcha verification failed!",recaptchaRef.current.reset())
    }
    else
    {
      addDoc(userCollectionRef,{
        FirstName: inputs.firstname,
        LastName: inputs.lastname,
        Email: inputs.email,
        Organisation: inputs.org,
        Industry: inputs.industry,
        JobTitle: inputs.job,
        Message: inputs.message,
        IsNewsletterRequired: inputs.newsletter
      }).then((res) => openNotification("We will get back to you on your registered email address"),setInputs(""),recaptchaRef.current.reset())
        .catch((error) => errorNotification("Please try again later"),recaptchaRef.current.reset())
    }
  }

  return (
    <>
      <Container>
        <FormWrap>
          <Navbarpages/>
          <Formcontent>
            <Form action="#" onSubmit={handleSubmit}>
              <FormH1>Contact Us</FormH1>
              <FormLabel htmlFor="for" >First Name</FormLabel>
              <FormInput type="text" name="firstname" value={inputs.firstname || ""} onChange={handleChange} required />
              <FormLabel htmlFor="for" >Last Name</FormLabel>
              <FormInput type="text" name="lastname" value={inputs.lastname || ""} onChange={handleChange} required />
              <FormLabel htmlFor="for">Email</FormLabel>
              <FormInput type="email" name="email" value={inputs.email || ""} onChange={handleChange} required />
              <FormLabel htmlFor="for">Organisation</FormLabel>
              <FormInput type="text" name="org" value={inputs.org || ""} onChange={handleChange} required />
              <FormLabel htmlFor="for">Industry</FormLabel>
              <FormInput type="text" name="industry" value={inputs.industry || ""} onChange={handleChange} required />
              <FormLabel htmlFor="for">Job Title</FormLabel>
              <FormInput type="text" name="job" value={inputs.job || ""} onChange={handleChange} required />
              <FormLabel htmlFor="for">Message</FormLabel>
              <FormInput type="text" name="message" value={inputs.message || ""} onChange={handleChange} required />
              <span>
                <FormInput
                  type="checkbox"
                  id="checkboxid"
                  size="8px"
                  name="newsletter" value={inputs.newsletter || ""} onChange={handleChange} 
                />
                <Text> Subscribe to Newsletter</Text>
              </span>
              <ReCapcha
                sitekey="6LdUzUklAAAAAEsz1qEjbqnaDc4EKbLgw_YcmxTD"
                ref={recaptchaRef}
              />
              <FormButton type="submit">Continue</FormButton>
            </Form>
          </Formcontent>
        </FormWrap>
      </Container>
      <Footer/>
    </>
  );
};

export default ContactForm;
