import React, { useState, useEffect } from "react";
import { Chrono } from "react-chrono";
import data from "./data";
import Navbarpages from "../NavBarPages/Navbarpages";

export default function TimeLine() {
  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 768px)").matches);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    const handleResize = () => setIsMobile(mediaQuery.matches);

    mediaQuery.addEventListener("change", handleResize);

    return () => {
      mediaQuery.removeEventListener("change", handleResize);
    };
  }, []);

  const handleItemSelected = (item) => {
    // Handle item selection logic here
  };

  return (
    <div>
      <Navbarpages />
      <div className="timeline-app" style={{ width: "100%", margin: "20px auto", overflowX: "scroll"}}>
        <div style={{ width: "100%", marginTop: "20px" }}>
          <Chrono
            items={data}
            mode={isMobile ? "VERTICAL" : "HORIZONTAL"}
            showAllCardsHorizontal={isMobile ? false : true}
            cardWidth={isMobile ? "100%" : 300}
            cardHeight={300}
            contentDetailsHeight={100}
            enableOutline={false}
            borderLessCards={true}
            hideControls={true}
            scrollable={isMobile ? false : true}
            fontSizes={{
              title: "1rem",
            }}
            theme={{
              primary: "rgb(1, 1, 1)",
              secondary: "rgb(1, 191, 113)",
              cardBgColor: "rgb(1, 191, 113)",
              titleColor: "black",
              titleColorActive: "rgb(255, 255, 255)",
              cardTitleColor: "black",
            }}
            slideShow={isMobile ? false : true}
            slideShowType="reveal"
            onItemSelected={handleItemSelected}
          />
        </div>
      </div>
    </div>
  );
}
