import Resource from "../components/Resource";
import Footer from "../components/Footer";

const ResourcesPage = () => {
  return (
    <>
      <Resource />
      <Footer />
    </>
  );
};

export default ResourcesPage;
