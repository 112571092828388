// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getFirestore} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAZyTet2W9LSKyqyIzELH1LpW2ZTp-xFmU",
  authDomain: "tryambh.firebaseapp.com",
  databaseURL: "https://tryambh-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "tryambh",
  storageBucket: "tryambh.appspot.com",
  messagingSenderId: "1051311171516",
  appId: "1:1051311171516:web:93398dce15848353a6edb9",
  measurementId: "G-V11P03X1G9"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

const analytics = getAnalytics(app);