import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export default function Navbarpages() {
  return (
    <Nav>
      <Icon to="/">Tryambh</Icon>
    </Nav>
  );
}

const Nav = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: black;
`;

export const Icon = styled(Link)`
  margin-left: 32px;
  margin-top: 32px;
  margin-bottom: 32px;
  text-decoration: none;
  color: #fff;
  font-weight: 700;
  font-size: 32px;

  @media screen and (max-width: 480px) {
    margin-left: 16px;
    margin-top: 8px;
  }
`;
