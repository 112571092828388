import data from "../../images/possibility.jpg";
import tools from "../../images/3d-2.svg";
import sensor from "../../images/sensor.svg";

import archive from "../../images/archive.svg";
import mail from "../../images/email.svg";

export const home0bjOne = {
  id: "about",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "with our unique",
  headline: "Physics Based Digital Twins",
  description:
    "Get access to real time insights from your assets like never before and deploy simulations to see beyond time.  ",
  buttonLabel: "",
  imgStart: false,
  img: tools,
  alt: "Car",
  dark: true,
  primary: true,
  darkText: false
  // toPage: "/resources"
};

export const home0bjTwo = {
  id: "why us",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Our twins require,",
  headline: "Minimum onsite sensors & zero onsite computation!",
  description:
    "Based on our proprietary algorithms. The twin scales with your requirements. ",
  buttonLabel: "More..",
  imgStart: true,
  img: sensor,
  alt: "Car",
  dark: true,
  primary: true,
  darkText: true
  // toPage: "/whyus_link"
};

export const home0bjThree = {
  id: "possibilities",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "The sky is the limit,",
  headline: "Our Twins & systems can be deployed for your custom requirements.",
  description:
    "For applications ranging from real time asset health & condition monitoring to incident prevention via simulated predictions. ",
  buttonLabel: "Explore",
  imgStart: false,
  img: data,
  alt: "Car",
  dark: true,
  primary: true,
  darkText: false,
  toPage: "/resources"
};

export const home0bjFour = {
  id: "resources",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "",
  headline: "Find our application in your industry",
  description: "Check our resources",
  buttonLabel: "Explore",
  imgStart: true,
  img: archive,
  alt: "Car",
  dark: true,
  primary: true,
  darkText: true,
  toPage: "/resources"
};

export const home0bjFive = {
  id: "contact",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "",
  headline: "Let's get your assets alive!",
  description: "Write to us and we'll get back to you.  ",
  buttonLabel: "Contact us!",
  imgStart: false,
  img: mail,
  alt: "Car",
  dark: true,
  primary: true,
  darkText: false,
  toPage: "/contact"
};
