import Arr from "../../images/rig.jpg";
import ind from "../../images/indusrty.jpg";
import windmill from "../../images/windmill.jpg";
import nuclear from "../../images/nuclear.jpg";
import loader from "../../images/demo_dozer.png";

const PropertyDetail = [
  {
    id: "1",
    Image: Arr,
    heading: "Digital Twin's for off shore",
    span:
      "Explore the possible applications of our digital twin technology in off shore oil and gas rigs. Download our report for more!",
    amount: "",
    tags: "Coming soon",
    download: "",
  },
  {
    id: "2",
    Image: ind,
    heading: "Digital twin technology for Industry",
    span:
      "From predictive maintenance to active real-time monitoring, find out how physics based digital twins can enhance your production.",
    amount: "",
    tags: "Coming soon",
    download: "",
  },
  {
    id: "3",
    Image: windmill,
    heading: "Our Twins for off shore & in land wind ",
    span:
      "How our physics based twins can be deployed for optimizing and maintaining wind farms",
    amount: "",
    tags: "Coming soon",
    download: "",
  },
  {
    id: "4",
    Image: nuclear,
    heading: "Digital twins for a safer nuclear power",
    span:
      "Find out how digital twin based proactive monitoring and detection systems can ensure safer operation of nuclear power plants!",
    amount: "",
    tags: "Coming soon",
    download: "",
  },
  {
    id: "5",
    Image: loader,
    heading: "Active Physics based twins and systems for mining",
    span: "Deploy real-time structural level asset monitoring for mining asset monitoring and take the right decisions based on unmatched asset insights and load cycles",
    amount: "",
    tags: "Coming soon"
  }
  // {
  //   id: "6",
  //   Image: "",
  //   heading: "Latest Digital Twin",
  //   span: "Brief description of the item",
  //   amount: "",
  //   tags: ""
  // }
];

export default PropertyDetail;
