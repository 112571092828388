import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  WebsiteRights
} from "./FooterElements";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>
                Tryambh Innovations Private Limited
              </FooterLinkTitle>
              <FooterLink to="/contact">Contact Us</FooterLink>
              <FooterLink to="/resources">Resources</FooterLink>
              <FooterLink to="/whyus_link">Tech</FooterLink>
              {/* <FooterLink to="/signin">Services</FooterLink>
              <FooterLink to="/signin">About Founder</FooterLink>
              <FooterLink to="/signin">Terms</FooterLink> */}
            </FooterLinkItems>
            {/* <FooterLinkItems>
              <FooterLinkTitle>About Us</FooterLinkTitle>
              <FooterLink to="/signin">About us</FooterLink>
              <FooterLink to="/signin">Tech</FooterLink>
              <FooterLink to="/signin">Services</FooterLink>
              <FooterLink to="/signin">About Founder</FooterLink>
              <FooterLink to="/signin">Terms</FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>About Us</FooterLinkTitle>
              <FooterLink to="/signin">About us</FooterLink>
              <FooterLink to="/signin">Tech</FooterLink>
              <FooterLink to="/signin">Services</FooterLink>
              <FooterLink to="/signin">About Founder</FooterLink>
              <FooterLink to="/signin">Terms</FooterLink>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>About Us</FooterLinkTitle>
              <FooterLink to="/signin">About us</FooterLink>
              <FooterLink to="/signin">Tech</FooterLink>
              <FooterLink to="/signin">Services</FooterLink>
              <FooterLink to="/signin">About Founder</FooterLink>
              <FooterLink to="/signin">Terms</FooterLink>
            </FooterLinkItems> */}
          </FooterLinksWrapper>
        </FooterLinksContainer>
        <WebsiteRights>
          Tryambh Innovations © {new Date().getFullYear()}
        </WebsiteRights>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
